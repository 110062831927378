import { Component, Vue } from 'vue-property-decorator'

type ImagePaths = [
  // 問題ページでは配列一つ、前結果ページでは複数の画像のまとまりが返る
  {
    d: string[] // 読解文 配列で複数保持
    m: string[] // 問題 配列で複数保持
    t: string[] // 解答 配列で複数保持
    s: string[] // 解説 配列で複数保持
    j: string // 指示文 文字列で単体保持
  }
]

@Component
export default class WebApi extends Vue {
  protected mounted() {
    Vue.prototype.$logger.info('-- WebApi mounted')
  }

  /**
   * TODO GDLS-600 ドリル開始Web API
   *
   * @param params ドリル開始に必要な連想配列
   * {
   *   classModeCode: 授業モード（必須） 通常授業、定期テスト、補助教材等
   *   classCategoryCode: ドリル種別（必須） 理解度確認テスト、演習、宿題、確認テスト、一問一答等
   *   curriculumModeCode: カリキュラムモード（必須） OO（一問一答）
   *   subjectCode: 教科コード（任意） 定期テストの場合は必要
   *   curriculumSUnitId: 小項目ID（任意） 宿題、確認テスト、定期テスト以外の場合は必須
   *   lessonId: 授業ID（任意） 授業中の場合はresult_drillsと紐付けるため必要
   *   resultDrillId: ドリル結果ID（任意） 宿題、確認テストの場合は必須
   *   termExamId: ドリルの箱のID（任意） 定期テストの場合は必須
   *   publisherCode: 教科書コード
   *   searchType: 入試モードの問題検索タイプ
   *   years: 年度の配列(入試モードの問題検索用)
   *   prefectureId: 都道府県ID(入試モードの問題検索用)
   *   pageNums: ページ番号配列(入試モードの問題検索用)
   *   includeLivingPrefecture: 住んでいる県の問題を含めるか(入試モードの問題検索用)
   *   curriculumSUnitIds: 小項目ID配列(入試モードの問題検索用)
   * }
   * @return ドリル結果を格納するテーブルとページ番号が返る
   */
  protected async startDrillApi(params: {
    classModeCode: string
    classCategoryCode: string
    curriculumModeCode?: string
    subjectCode?: string
    curriculumSUnitId?: number
    lessonId?: number
    resultDrillId?: number
    termExamId?: number
    publisherCode?: string
    searchType?: string
    years?: number[]
    prefectureId?: number
    pageNums?: number[]
    includeLivingPrefecture?: boolean
    curriculumSUnitIds?: number[]
  }): Promise<any> {
    Vue.prototype.$logger.info('-- WebApi startDrill')
    Vue.prototype.$logger.info(params)

    // Web APIを投げてドリルを開始する
    try {
      const result = await Vue.prototype.$http.httpWithToken.post('/drill/start', params)
      return result.data
    } catch (e) {
      if (e.response.data.status === 422) {
        // 理解度確認テストが存在しない小項目を学習しようとした場合
        alert('現在は学習できません')
      } else {
        throw 'Drill Error!'
      }
    }
  }

  /**
   * ドリルのページ開始時に呼ぶWeb API
   * ページ開始時に授業IDとドリル結果とページ番号を渡し表示に必要な問題データを取得する
   *
   * @param params { 授業ID, ドリル結果ID, ページ番号 }
   */
  protected async startProblemApi(params: { lessonId?: number; resultDrillId?: number; page: number }): Promise<any> {
    Vue.prototype.$logger.info('-- WebApi startProblemApi')
    this.$store.commit('drills/setProcessed', false)

    // Web APIを投げてドリルデータを取得する
    let result: any
    try {
      result = await Vue.prototype.$http.httpWithToken.post('/drill/problem-start', params)
    } catch {
      // TODO エラー処理
      throw 'Drill Error!'
    }
    Vue.prototype.$logger.info(result)

    // TODO 以降はWebApi.tsとは別ファイルにて処理する
    // ドリルデータをストアに格納する
    this.$store.dispatch('drills/setDrillData', result.data)

    // ドリルに関連する画像を取得する
    await this.imagePaths()

    // 読み込み完了
    this.$store.commit('drills/setProcessed', true)
  }

  /**
   * ドリルのページ遷移時に呼ぶWeb API
   * ページ開始時にドリル結果IDとページ番号を渡し表示に必要な問題データを取得する
   *
   * @param params { ドリル結果ID, ページ番号 }
   */
  protected async endProblemApi(params: { resultDrillId?: number; page: number }): Promise<any> {
    Vue.prototype.$logger.info('-- WebApi endProblemApi')

    // Web APIを投げてドリルデータを取得する
    try {
      await Vue.prototype.$http.httpWithToken.post('/drill/problem-end', params)
    } catch {
      // TODO エラー処理
      throw 'Drill Error!'
    }
  }

  protected async startScoringAllApi(params: { lessonId?: number; resultDrillId: number }): Promise<any> {
    Vue.prototype.$logger.info('-- WebApi startScoringAllApi')
    this.$store.commit('drills/setProcessed', false)

    // Web APIを投げて正誤入力に必要な情報を取得する
    let result: any
    try {
      result = await Vue.prototype.$http.httpWithToken.post('/drill/scoring-all-start', params)
    } catch {
      // TODO エラー処理
      throw 'Drill Error!'
    }

    Vue.prototype.$logger.info(result)

    // TODO 以降はWebApi.tsとは別ファイルにて処理する
    // ドリルデータをストアに格納する
    this.$store.dispatch('drills/setDrillData', result.data)

    // ドリルに関連する画像を取得する
    await this.imagePaths()

    // 読み込み完了
    this.$store.commit('drills/setProcessed', true)
  }

  protected async startScoringUnitApi(params: {
    lessonId?: number
    resultDrillId: number
    page: number
  }): Promise<any> {
    Vue.prototype.$logger.info('-- WebApi startScoringUnitApi')
    this.$store.commit('drills/setProcessed', false)

    // Web APIを投げて正誤入力に必要な情報を取得する
    let result: any
    try {
      result = await Vue.prototype.$http.httpWithToken.post('/drill/scoring-unit-start', params)
    } catch {
      // TODO エラー処理
      throw 'Drill Error!'
    }

    Vue.prototype.$logger.info(result)

    // TODO 以降はWebApi.tsとは別ファイルにて処理する
    // ドリルデータをストアに格納する
    this.$store.dispatch('drills/setDrillData', result.data)

    // ドリルに関連する画像を取得する
    await this.imagePaths()

    // 読み込み完了
    this.$store.commit('drills/setProcessed', true)
  }

  protected async registerScoringAllApi(params: { lessonId?: number; resultDrillId: number; data: any }): Promise<any> {
    Vue.prototype.$logger.info('-- WebApi registerScoringAllApi')
    Vue.prototype.$logger.info(params)

    // Web APIを投げて正誤入力に必要な情報を取得する
    try {
      await Vue.prototype.$http.httpWithToken.post('/drill/scoring-all-register', params)
    } catch {
      // TODO エラー処理
      throw 'Drill Error!'
    }
  }

  protected async registerScoringUnitApi(params: {
    lessonId?: number
    resultDrillId: number
    page: number
    data: any
  }): Promise<any> {
    Vue.prototype.$logger.info('-- WebApi registerScoringUnitApi')
    Vue.prototype.$logger.info(params)

    // Web APIを投げて正誤入力に必要な情報を取得する
    try {
      await Vue.prototype.$http.httpWithToken.post('/drill/scoring-unit-register', params)
    } catch {
      // TODO エラー処理
      throw 'Drill Error!'
    }
  }

  protected async startResultAllApi(params: { lessonId?: number; resultDrillId?: number }): Promise<any> {
    Vue.prototype.$logger.info('-- WebApi startResultAllApi')
    this.$store.commit('drills/setProcessed', false)

    // Web APIを投げて正誤入力に必要な情報を取得する
    let result: any
    try {
      result = await Vue.prototype.$http.httpWithToken.post('/drill/result-all-start', params)
    } catch {
      // TODO エラー処理
      throw 'Drill Error!'
    }

    Vue.prototype.$logger.info(result)

    // TODO 以降はWebApi.tsとは別ファイルにて処理する
    // ドリルデータをストアに格納する
    this.$store.dispatch('drills/setDrillData', result.data)

    // ドリルに関連する画像を取得する
    await this.imagePaths()

    // 読み込み完了
    this.$store.commit('drills/setProcessed', true)
  }

  protected async startResultUnitApi(params: { lessonId?: number; resultDrillId: number; page: number }): Promise<any> {
    Vue.prototype.$logger.info('-- WebApi startResultUnitApi')
    this.$store.commit('drills/setProcessed', false)

    // Web APIを投げて正誤入力に必要な情報を取得する
    let result: any
    try {
      result = await Vue.prototype.$http.httpWithToken.post('/drill/result-unit-start', params)
    } catch {
      // TODO エラー処理
      throw 'Drill Error!'
    }

    Vue.prototype.$logger.info(result)

    // TODO 以降はWebApi.tsとは別ファイルにて処理する
    // ドリルデータをストアに格納する
    this.$store.dispatch('drills/setDrillData', result.data)

    // ドリルに関連する画像を取得する
    await this.imagePaths()

    // 読み込み完了
    this.$store.commit('drills/setProcessed', true)
  }

  /**
   * ストアのimagePathsに格納した画像パスから画像を全て取得しimagePathsと同じ階層で画像のURLを保存する
   */
  protected async imagePaths(): Promise<any> {
    Vue.prototype.$logger.info('-- WebApi imagePaths')

    // 画像パス
    const imagePaths: ImagePaths = this.$store.getters['drills/imagePaths']

    // 並列に画像パスから画像データを取得する
    const promises: any[] = []
    // 問題ページでは配列が1つ、全結果画面では配列が複数あるため全結果画面でも流用できるように配列でループ
    imagePaths.forEach((problemImages: any, problemImageKey: number) => {
      // 問題画像は読解文、問題、解答、解説、指示文がある連想配列をループ
      for (const [imageType, problemImage] of Object.entries(problemImages)) {
        if (Array.isArray(problemImage)) {
          // 指示文以外の場合
          problemImage.forEach((imagePath: string, index: number) => {
            promises.push(
              this.imagePath(imagePath, { problemImageKey: problemImageKey, imageType: imageType, index: index })
            )
          })
        } else if (typeof problemImage === 'string') {
          // 指示文の場合
          promises.push(this.imagePath(problemImage, { problemImageKey: problemImageKey, imageType: imageType }))
        }
      }
    })

    // 全ての画像の読み込みが完了したらimagePathsと同様の構造でurlを格納する
    const images = new Array(imagePaths.length)
    await Promise.all(promises).then((res: any) => {
      Vue.prototype.$logger.info('-- Promise complete')

      // ページ分、配列で画像データを持っているresを回してimagePathの構造と合わせる
      for (const value of res) {
        const objectURL = value[0]
        const imagePayload = value[1]

        // ページごとに連想配列で初期化
        if (!images[imagePayload.problemImageKey]) images[imagePayload.problemImageKey] = {}

        // 連想配列に画像タイプをキーで初期化
        if (!images[imagePayload.problemImageKey][imagePayload.imageType]) {
          // 画像タイプが読解文、問題、解答、解説（指示文以外）は複数画像存在し得るため配列で初期化する
          if (imagePayload.index >= 0) {
            images[imagePayload.problemImageKey][imagePayload.imageType] = new Array(imagePayload.index)
          }
        }

        if (imagePayload.index >= 0) {
          // 指示文以外は配列に追加
          images[imagePayload.problemImageKey][imagePayload.imageType][imagePayload.index] = objectURL
        } else {
          // 指示文の場合はキーに対して文字列で追加
          images[imagePayload.problemImageKey][imagePayload.imageType] = objectURL
        }
      }
    })

    this.$store.commit('drills/setImageUrls', images)
  }

  /**
   * 一つの画像パスを引数にしてBlob形式で取得した画像のURLを返す
   *
   * @param imagePath 画像パス
   * @param payload 戻ってきた値を特定するように追加
   * @return [画像URL, payload]
   */
  protected async imagePath(imagePath: string, payload: any): Promise<any[]> {
    Vue.prototype.$logger.info(`-- WebApi imagePath ${imagePath}`)

    try {
      // 画像取得
      Vue.prototype.$http.httpWithToken.defaults.responseType = 'blob'
      Vue.prototype.$http.httpWithToken.defaults.dataType = 'binary'
      const result = await Vue.prototype.$http.httpWithToken.get(`/image?path=${imagePath}`)
      const blob = new Blob([result.data])

      // 終わったらresponseTypeをjsonに戻す
      Vue.prototype.$http.httpWithToken.defaults.responseType = 'json'

      return [window.URL.createObjectURL(blob), payload]
    } catch {
      // TODO エラー処理
      throw 'Drill Error!'
    }
  }
}

// ? ドリルデータサンプル
// public drillData = {
//   data: [
//     {
//       page: 1,
//       problems: [
//         {
//           sProblemId: 1,
//           number: 2,
//           results: [true, false],
//           inputs: ['hoge', 'geho'],
//           answerFormat: 1,
//           selectTag: ['選択肢1', '選択肢2'],
//           answerText: '答え',
//         },
//         {
//           sProblemId: 2,
//           number: 3,
//           results: [true, false, true],
//           inputs: ['0', '1'],
//           answerFormat: 2,
//           selectTag: ['選択肢1', '選択肢2', '選択肢3'],
//           answerText: '答え',
//         },
//       ],
//       isLProblem: true | false
//       imagePath: {
//         d: 'hogehoge', // 読解文
//         m: ['hogehoge1', 'hogehoge1', 'hogehoge2'], // 問題文
//         j: 'hogehoge', // 指示文
//       },
//     },
//     {
//       page: 2,
//       problems: [
//         { sProblemId: 1, number: 3 },
//         { sProblemId: 2, number: 3 },
//       ],
//       imagePath: {
//         d: 'hogehoge', // 読解文
//         m: ['hogehoge1', 'hogehoge1', 'hogehoge2'], // 問題文
//         j: 'hogehoge', // 指示文
//       },
//     },
//   ],
//   prevUrl: '',
//   nextUrl: '',
// }
