






















import { Component, Mixins, Vue, Prop } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import FillRound from '@/components/atoms/FillRound.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import VueRouter from 'vue-router'
import DrillWebApi from '@/mixins/drills/WebApi'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import LoadEnableSubjectsApi from '@/mixins/utils/LoadEnableSubjectsApi'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import StudentBottomPanel from '@/components/organisms/StudentBottomPanel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

Component.registerHooks(['beforeRouteUpdate'])

@Component({
  components: {
    SidebarSwitcher,
    ColoredBox,
    FillRound,
    StudentBottomPanel,
    ButtonBase,
  },
})
export default class Homework extends Mixins(
  StyleSubjectBackground,
  DrillWebApi,
  LessonStudent,
  ClassModeMethods,
  LoadEnableSubjectsApi,
  ClassModeChangeAble
) {
  private get noticeCurrentPageText() {
    let result = '教科選択中'

    // 補助教材の場合
    if (this.isAssistMode) result = '教材選択中（補助）'

    return result
  }

  private noticePageTitle = ''

  public async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.resetCurriculumSUnitsGrade()
    await this.checkClassModeAndSideMenuMode()
    this.noticeGroup()
    // テストの場合はテスト範囲の設定されている教科のみ実施可能
    this.loadEnableSubjects()
    Vue.prototype.$loading.complete()
  }

  public beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    Vue.prototype.$logger.info('-- SelectSubject beforeRouteUpdate')

    this.noticeGroup()

    next()
  }

  private async noticeGroup() {
    // このページでは固定文言のためページ遷移時またはbeforeRouteUpdate時に現在ページを伝える
    this.noticeOrStackCurrentPage({ currentPage: this.noticeCurrentPageText, pageTitle: this.noticePageTitle })

    // router.pushではsetTimeoutがクリアされないためセットする前にクリアして初期化しておく
    const setTimeoutIdQueueSize = await this.$store.dispatch('queue/size', 'setTimeoutIdQueue')
    for (let i = 0; i < setTimeoutIdQueueSize; i++) {
      const setTimeoutId = await this.$store.dispatch('queue/dequeue', 'setTimeoutIdQueue')
      clearTimeout(setTimeoutId)
    }

    // 5分、10分経過後にポイントを付与する
    this.noticeOrStackCurrentPageDelayed(10, { point: 5 })
    this.noticeOrStackCurrentPageDelayed(20, { point: 10 })
  }

  private subjectDatas: { text: string; code: string }[] = []

  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private serviceCode = Vue.prototype.$cookies.get('dataGdls').serviceCode

  /**
   * クラスカテゴリコード
   * 通常授業の場合は確認テスト、宿題の教科選択で利用
   *
   * 静的ルーティングから遷移してくる場合は何も渡ってこない
   */
  private get classCategoryCode() {
    return this.$route.params.classCategoryCode || ''
  }

  // カテゴリータイトル
  private get categoryTitle() {
    return '(宿題)'
  }

  private async selectSubject(subjectCode: string): Promise<any> {
    // 教科コード保存
    await this.setSubjectCode(subjectCode)
    return this.$router.push(`/student/v3/confirmation/${this.classCategoryCode}`)
  }

  /**
   * 教科コードのcookieセット
   */
  private async setSubjectCode(subjectCode: string) {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['subjectCode'] = subjectCode
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  /**
   * サービス設定で有効な教科を取得し、教科プルダウンに反映
   */
  private async loadEnableSubjects() {
    // プルダウン候補の取得
    const enableSubjects = await this.loadGdlsSubjectsApi(this.branchId, this.userId)

    this.subjectDatas = enableSubjects.map((subject) => {
      return { text: subject.name, code: subject.code }
    })
  }

  /**
   * 項目リストの学年選択情報をリセットする
   */
  private async resetCurriculumSUnitsGrade() {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    delete cookie['curriculumSUnitsGrade']
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }
}
